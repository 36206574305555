import { takeLatest, put, call } from 'redux-saga/effects';
import { logout } from '../../Auth/redux/actions/auth.actions';
import { ERROR_MODAL } from '../../Common/Modals/modal.contants';
// import { ERROR_MODAL, NETWORK_ERROR } from '../../Common/Modals/modal.contants';
import { successMessage, toggleModal } from '../../Common/redux/common.actions';
import { UserService } from './user.services';
import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from './user.types';

function* workerGetUser() {
  try {
    const response = yield call(UserService.getUser);
    let _phone = {};
    if (!!response.data.data.phones) {
      _phone = response.data.data.phones.find(item => item.type === 0);
    }
    yield put({
      type: GET_USER_SUCCESS,
      payload: {
        ...response.data,
        phone: _phone,
      },
    });
  } catch (error) {
    if (error.response?.status === 401) {
      yield put({
        type: GET_USER_FAIL,
      });
      yield put(logout());
    } else if (error.response?.status === 500) {
      yield put(toggleModal(ERROR_MODAL));
    }
  }
}

function* workerUpdateUser({ payload }) {
  try {
    const response = yield call(UserService.updateUser, payload);
    yield put({
      type: UPDATE_USER_SUCCESS,
      payload: response.data.data,
    });
    yield put(successMessage('Ваши данные успешно сохранены!'));
  } catch (error) {
    yield put({
      type: UPDATE_USER_FAIL,
      payload: error.response.data ? error.response.data?.errors : null,
    });
  }
}

export function* userWatcher() {
  yield takeLatest(GET_USER, workerGetUser);
  yield takeLatest(UPDATE_USER, workerUpdateUser);
}
