import { http } from '../../Common/services/api.services';

export const BalanceService = {
  checkToken: token => http.get('/payment/invoice/' + token),

  chargeBalance: ({ data, id }) => http.post('/invoice/balance/me', data),

  sendPayment: ({ data, token }) =>
    http.post('/payment/invoice/' + token, data),

  fetchMyTransactions(params) {
    return http.get('/my-transactions', { params });
  },

  fetchMyInvoices(params) {
    return http.get('/my-invoices', { params });
  },

  cancelInvoice(token) {
    return http.put('/my-invoices/cancel/' + token);
  },

  getAllCashbacks: params => http.get('/cashback-wallet-histories', { params }),
};
