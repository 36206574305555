import { http } from '../../Common/services/api.services';

export const AddressService = {
  getAddresses: params => http.get('/my-address/my-address', { params }),

  getAddress: id => http.get('/my-address/my-address/' + id),

  addAddress: data => http.post('/my-address/my-address', data),

  deleteAddress: id => http.delete('/my-address/my-address/' + id),

  updateAddress: data => http.put('/my-address/my-address/' + data.id, data),

  setAddressMain: id => http.post('/my-address/my-address/set-base/' + id),

  getCountries: params => http.get('/address-tree/countries', { params }),

  getRegions: params => http.get('/address-tree/regions', { params }),

  getDistricts: params => http.get('/address-tree/districts', { params }),

  getStreets: byId => http.get('/address-tree/streets/' + byId),

  searchStreet: params => http.get('/address-tree/streets', { params }),

  getRecipients: params => http.get('/my-address/receivers', { params }),

  getSenders: params =>
    http.get('https://turonexpress.com/api/senders', { params }),

  addRecipient: (data, params = {}) =>
    http.post('/my-address/receivers', data, { params }),

  addSender: data => http.post('/my-address/senders', data),

  sendRecipient: (data, params) =>
    http.post('/my-address/store-in-step', data, { params }),

  sendSender: data => http.post('/my-address/store-in-step', data),

  deleteRecipient: id => http.delete('/my-address/receivers/' + id),

  deleteSender: id => http.delete('/my-address/senders/' + id),

  updateRecipient: (data, params = {}) =>
    http.put('/my-address/receivers/' + data.id, data, { params }),

  updateSender: data => http.put('/my-address/senders/' + data.id, data),

  sendPhoneForFill: data =>
    http.post('/my-address/receivers/send-fill-request', data),

  searchByZipcode: zipcode => http.get('/address-tree/zip-code/' + zipcode),
};
