import {
  FETCH_INVOICES,
  FETCH_TRANSACTIONS,
  GET_ALL_CASHBACKS_SUCCESS,
  PUSH_CASHBACKS,
  UPDATE_INVOICE,
} from './balance.types';

const initialState = {
  transactions: [],
  transactionMeta: {},
  invoices: [],
  cashbacks: [],
  cashbackMeta: {},
};

export const BalanceReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTIONS:
      return {
        ...state,
        transactionMeta: payload.transactionMeta,
        transactions: payload.resetData
          ? payload.data
          : [...state.transactions, ...payload.data],
      };
    case FETCH_INVOICES:
      return {
        ...state,
        invoices: payload.resetData
          ? payload.data
          : [...state.invoices, ...payload.data],
      };
    case UPDATE_INVOICE:
      return {
        ...state,
        invoices: state.invoices.map(invoice => {
          if (invoice.id === payload.id) {
            return payload;
          }
          return invoice;
        }),
      };
    case GET_ALL_CASHBACKS_SUCCESS:
      return {
        ...state,
        cashbacks: payload.data,
        cashbackMeta: payload.meta,
      };
    case PUSH_CASHBACKS:
      return {
        ...state,
        cashbacks: [...state.cashbacks, ...payload.data],
        cashbackMeta: payload.meta,
      };
    default:
      return state;
  }
};
