import { combineReducers } from 'redux';
import { AuthReducer } from '../../Auth/redux/reducer/auth.reducer';
import { AddressReducer } from '../../UserAddress/redux/address.reducer';
import { BalanceReducer } from '../../UserBalance/redux/balance.reducer';
import { OrderReducer } from '../../UserOrder/redux/order.reducer';
import { UserReducer } from '../../UserSettings/redux/user.reducer';
import { BoxReducer } from './boxes/boxes.reducer';
import { BranchReducer } from './branches/branch.reducer';
import { CommonReducer } from './common.reducer';
import { PhoneReducer } from './phone/phone.reducer';
import { ShopReducer } from './shops/shops.reducer';
import { StatusesReducer } from './statuses/statuses.reducer';

const rootReducer = combineReducers({
  auth: AuthReducer,
  common: CommonReducer,
  user: UserReducer,
  balance: BalanceReducer,
  address: AddressReducer,
  order: OrderReducer,
  branch: BranchReducer,
  box: BoxReducer,
  shop: ShopReducer,
  status: StatusesReducer,
  phone: PhoneReducer,
});

export default rootReducer;
