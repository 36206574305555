import {
  GET_BRANCHES,
  GET_BRANCHES_FAIL,
  GET_BRANCHES_SUCCESS,
  GET_HOME_BRANCHES_SUCCESS,
} from './branch.actions';

const initialState = {
  loading: false,
  branches: [],
  homeBranches: [],
};

export const BranchReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BRANCHES:
      return {
        ...state,
        loading: true,
      };
    case GET_BRANCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        branches: payload,
      };
    case GET_HOME_BRANCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        homeBranches: payload,
      };
    case GET_BRANCHES_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
