import { http } from '../../Common/services/api.services';

export const OrderService = {
  searchByTrack: data => http.post('/my-parcels/search', data),
  setOrderType: data => http.post('/my-parcels/step1', data),

  setInfoOrder: data =>
    http.post('/my-parcels/internet-order/step2/' + data.receive_type_id, data),

  setInternetDeclaration: ({ data, receive_type_id }) =>
    http.post('/my-parcels/internet-order/step2/' + receive_type_id, data),

  setRecipientId: ({ data, receive_type_id }) =>
    http.post('/my-parcels/internet-order/step4/' + receive_type_id, data),

  getOffers: id => http.get(`/offers?filter[service_type]=${id}`),

  confirmInternetOrder: ({ data, receive_type_id }) =>
    http.post('/my-parcels/internet-order/confirm/' + receive_type_id, data),

  setParcelDeclaration: ({ data, receive_type_id }) =>
    http.post('/my-parcels/send-parcel/step2/' + receive_type_id, data),

  getSenders: params =>
    http.get('/my-parcels/send-parcel/get-senders', { params }),

  setSenderId: ({ data, receive_type_id }) =>
    http.post('/my-parcels/send-parcel/step3/' + receive_type_id, data),

  setParcelRecipientId: ({ data, receive_type_id }) =>
    http.post('/my-parcels/send-parcel/step4/' + receive_type_id, data),

  setShipmentMethod: ({ data, receive_type_id }) =>
    http.post('/my-parcels/send-parcel/step5/' + receive_type_id, data),

  confirmParcel: ({ receive_type_id }) =>
    http.post('/my-parcels/send-parcel/confirm/' + receive_type_id),

  sendHelpDeclaration: ({ data, receive_type_id }) =>
    http.post('/my-parcels/help-to-shop/step2/' + receive_type_id, data),

  confirmHelpToShop: ({ data, receive_type_id }) =>
    http.post('/my-parcels/help-to-shop/confirm/' + receive_type_id, data),

  getApplications: params => http.get('/my-parcels/applications', { params }),

  getApplication: id => http.get('/my-parcels/applications/' + id),

  getPickupDays: id => http.get('/available-pickup-days/' + id),

  getOrder: id => http.get('/my-parcels/orders/' + id),

  getApplicationTabStatuses: tab => http.get('/my-parcels/tab-statuses/' + tab),

  getApplicationsStatuses: typeId =>
    http.get('/my-parcels/show-statuses/' + typeId),

  getShipmentMethods: id => http.get('/available-receive-types/' + id),

  updateApplication: (data, id) =>
    http.put('/my-parcels/applications/' + id, data),

  updateOrder: (data, id) => http.put('/my-parcels/orders/' + id, data),

  getLabel: id => http.post('/my-parcels/applications/get-label-url/' + id),

  getDeclaration: data => http.post('/export/pdf/declaration', data),

  cancelOrder: id => http.put('/my-parcels/applications/cancel-order/' + id),

  cancelApplication: id => http.put('/my-parcels/applications/cancel/' + id),

  restoreApplication: id => http.put('/my-parcels/applications/restore/' + id),

  combineApplications: data =>
    http.put('/my-parcels/applications/combine/' + data.ids.join(','), {
      receiver_profile_id: data.id,
    }),

  applyPromoCodeApplication: (data, id) =>
    http.post(`/my-parcels/applications/${id}/use-promo-code`, data),

  applyPromoCodeOrder: (data, id) =>
    http.post(`/my-parcels/orders/${id}/use-promo-code`, data),

  deletePromoCodeApplication: id =>
    http.delete(`/my-parcels/applications/${id}/delete-promo-code`),

  deletePromoCodeOrder: id =>
    http.delete(`/my-parcels/orders/${id}/delete-promo-code`),
};
