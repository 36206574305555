import { all, fork } from 'redux-saga/effects';
import { authWatcher } from '../../Auth/redux/sagas/auth.saga';
import { addressWatcher } from '../../UserAddress/redux/address.saga';
import { balanceWatcher } from '../../UserBalance/redux/balance.sagas';
import { orderWatcher } from '../../UserOrder/redux/order.saga';
import { userWatcher } from '../../UserSettings/redux/user.sagas';
import { boxWatcher } from './boxes/boxes.saga';
import { branchWatcher } from './branches/branch.saga';
import { commonWatcher } from './common.saga';
import { shopWatcher } from './shops/shops.saga';
import { statusesWatcher } from './statuses/statuses.saga';
import { phonePrefixWatcher } from './phone/phone.saga';

export default function* rootSaga() {
  yield all([
    fork(authWatcher),
    fork(commonWatcher),
    fork(userWatcher),
    fork(addressWatcher),
    fork(branchWatcher),
    fork(orderWatcher),
    fork(boxWatcher),
    fork(shopWatcher),
    fork(balanceWatcher),
    fork(statusesWatcher),
    fork(phonePrefixWatcher),
  ]);
}
