import { GET_COUNTRIES_PREFIX_SUCCESS } from './phone.action';

const initialState = {
  prefixes: [],
};

export const PhoneReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COUNTRIES_PREFIX_SUCCESS:
      return {
        ...state,
        prefixes: payload.data,
      };
    default:
      return state;
  }
};
