import {
  CLEAR_NOTIFICATION,
  GET_LINKS_SUCCESS,
  SET_BREADCRUMBS,
  SET_ERROR_MESSAGE,
  SET_SUCCESS_MESSAGE,
  SET_TITLE,
  TOGGLE_MODAL,
} from './common.actions';

const initialState = {
  successMessage: null,
  errorMessage: null,
  modals: {},
  breadcrumbs: [],
  topMenu: [],
  footerMenu: [],
  title: null,
};

export const CommonReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: payload,
      };
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: {
          text: payload.text,
          position: payload.position,
        },
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: payload,
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        modals: {
          [payload.modalName]: !state.modals[payload.modalName],
        },
      };
    case GET_LINKS_SUCCESS:
      return {
        ...state,
        topMenu: payload.top,
        footerMenu: payload.footerMenu,
      };
    case SET_TITLE:
      return {
        ...state,
        title: payload,
      };
    default:
      return state;
  }
};
