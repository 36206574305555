import {
  CLEAR_ORDER,
  GET_OFFERS_SUCCESS,
  GET_SENDERS_SUCCESS,
  LOADING_SUBMIT,
  SET_DISABLED,
  SET_HEADER_TEXT,
  SET_HELP_DECLARATION,
  SET_OFFERS,
  SET_ORDER_DECLARATION,
  SET_ORDER_INFO,
  SET_ORDER_TYPE,
  SET_PARCEL_DECLARATION,
  SET_RECEIVER_DATA,
  SET_RECEIVE_TYPE_ID,
  SET_RECIPIENT_ID,
  SET_SENDER_DATA,
  SET_SENDER_ID,
  SET_STEP_LENGTH,
  STEP_NEXT,
  STEP_PREV,
  SUCCESS_ORDER,
  GET_APPLICATIONS_FAIL,
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS,
  GET_APPLICATION_STATUSES_SUCCESS,
  GET_APPLICATION,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAIL,
  // GET_SHIPMENT_METHODS,
  GET_SHIPMENT_METHODS_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  GET_APPLICATION_STATUSES,
  GET_APPLICATION_STATUSES_FAIL,
  REMOVE_APPLICATION,
  GET_APPLICATION_TAB_STATUSES,
  GET_APPLICATION_TAB_STATUSES_SUCCESS,
  GET_APPLICATION_TAB_STATUSES_FAIL,
  GET_PICKUP_DAYS_SUCCESS,
  GET_PICKUP_DAYS,
  GET_OFFERS,
  GET_OFFERS_FAIL,
  UPDATE_APPLICATION,
  SET_TARIFF,
  SELECT_BRANCH_ID,
} from './order.types';

const initialState = {
  step: 1,
  stepLength: 5,
  headerText: '',
  orderType: null,
  receive_type_id: null,
  submitLoading: false,
  disabled: false,
  // internet order
  orderInfo: {
    name: '',
    // shop_name: '',
    branch_id: 2,
  },
  internetItems: {
    name: '',
    track_number: '',
    branch_id: 2,
    items: [
      {
        name: '',
        url: '',
        track_number: '',
        quantity: '',
        total_price: '',
        total_weight: '',
      },
    ],
  },
  itemTemp: {
    name: '',
    url: '',
    track_number: '',
    quantity: '',
    price: '',
    weight: '',
  },
  receiver_profile_id: null,
  offers: [],
  loadingOffers: false,
  offerValues: {
    offers: [],
  },
  successOrder: {
    show: false,
    deliver: false,
    headerText: '',
    bodytext: '',
    locationlink: '',
    downloadLink: '',
  },

  parcelDeclaration: {
    name: '',
    approximate_total_weight: 0,
    // branch_id: 2,
    items: [
      {
        name: '',
        quantity: '',
        total_price: '',
      },
    ],
  },
  tariff: null,

  senders: [],
  sender_profile_id: null,
  sender_address_id: null,

  senderData: {},
  receiverData: {},

  helpDeclaration: {
    name: '',
    branch_id: 2,
    items: [
      {
        name: '',
        url: '',
        quantity: '',
        price: '',
        width: '',
        notes: '',
        color: '',
        instead_color: '',
        size: '',
        instead_size: '',
        show: false,
      },
    ],
  },
  helpItemTemp: {
    name: '',
    url: '',
    quantity: '',
    price: '',
    width: '',
    notes: '',
    color: '',
    instead_color: '',
    size: '',
    instead_size: '',
    show: false,
  },
  methods: [],
  pickupDays: [],
  selected_branch_id: null,
};

const applicationState = {
  applications: [],
  loading: false,
  loadingMore: false,
  applicationPage: {},
  statuses: [],
  tabStatuses: {
    statuses: [],
    tab: null,
  },
  application: {},
  applicationError: false,
  loadingApplication: false,
  applicationItems: {
    items: [
      {
        category: '',
        name: '',
        quantity: 0,
        weight: 0,
        price: 0,
        total_weight: 0,
        total_price: 0,
      },
    ],
  },
  applicationItemTemp: {
    category: '',
    name: '',
    quantity: 0,
    weight: 0,
    price: 0,
    total_weight: 0,
    total_price: 0,
  },
  orders: [],
  orderPage: {},
};

export const OrderReducer = (
  state = { ...initialState, ...applicationState },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_HEADER_TEXT:
      return {
        ...state,
        headerText: payload,
      };
    case SET_ORDER_TYPE:
      return {
        ...state,
        orderType: payload,
      };
    case SET_DISABLED:
      return {
        ...state,
        disabled: payload,
      };
    case STEP_NEXT:
      return {
        ...state,
        step: state.step + 1,
      };
    case STEP_PREV:
      return {
        ...state,
        step: state.step - 1,
      };
    case LOADING_SUBMIT:
      return {
        ...state,
        submitLoading: payload,
      };
    case SET_ORDER_INFO:
      return {
        ...state,
        orderInfo: payload,
      };
    case SET_ORDER_DECLARATION:
      return {
        ...state,
        internetItems: payload,
      };
    case SET_RECIPIENT_ID:
      return {
        ...state,
        receiver_profile_id: payload,
      };
    case SET_SENDER_ID:
      return {
        ...state,
        sender_profile_id: payload.sender_profile_id,
        sender_address_id: payload.sender_address_id,
      };

    case SET_RECEIVE_TYPE_ID:
      return {
        ...state,
        receive_type_id: payload,
      };
    case GET_OFFERS:
      return {
        ...state,
        loadingOffers: true,
      };
    case GET_OFFERS_SUCCESS:
      return {
        ...state,
        loadingOffers: false,
        offers: payload,
      };
    case GET_OFFERS_FAIL:
      return {
        ...state,
        loadingOffers: false,
      };
    case SET_OFFERS:
      return {
        ...state,
        offerValues: payload,
      };
    case SUCCESS_ORDER:
      return {
        ...state,
        successOrder: payload,
      };
    case SET_PARCEL_DECLARATION:
      return {
        ...state,
        parcelDeclaration: payload,
      };
    case SET_TARIFF:
      return {
        ...state,
        tariff: payload,
      };
    case GET_SENDERS_SUCCESS:
      return {
        ...state,
        senders: payload,
      };
    case SET_SENDER_DATA:
      return {
        ...state,
        senderData: payload,
      };
    case SET_RECEIVER_DATA:
      return {
        ...state,
        receiverData: payload,
      };
    case SET_STEP_LENGTH:
      return {
        ...state,
        stepLength: payload,
      };
    case SET_HELP_DECLARATION:
      return {
        ...state,
        helpDeclaration: payload,
      };
    case GET_SHIPMENT_METHODS_SUCCESS:
      return {
        ...state,
        methods: payload,
      };
    case CLEAR_ORDER:
      return {
        ...state,
        ...initialState,
      };
    // -------------------------------
    // -------------------------------
    case GET_APPLICATIONS:
      return {
        ...state,
        loading: true,
        applications: [],
        applicationError: false,
      };
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        applications: payload,
      };
    case GET_APPLICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        applications: [],
      };
    case REMOVE_APPLICATION:
      return {
        ...state,
        applications: state.applications.filter(item => item.index !== payload),
      };
    case GET_APPLICATION:
      return {
        ...state,
        loadingApplication: true,
        applicationError: false,
      };
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        loadingApplication: false,
        application: payload,
      };
    case GET_APPLICATION_FAIL:
      return {
        ...state,
        loadingApplication: false,
        applicationError: true,
      };
    case GET_ORDER:
      return {
        ...state,
        loadingApplication: true,
        applicationError: false,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        loadingApplication: false,
        application: payload,
      };
    case GET_ORDER_FAIL:
      return {
        ...state,
        loadingApplication: false,
        applicationError: true,
      };
    case UPDATE_APPLICATION:
      return {
        ...state,
        application: payload,
      };
    case GET_APPLICATION_STATUSES:
      return {
        ...state,
        loading: true,
        statuses: [],
      };
    case GET_APPLICATION_STATUSES_SUCCESS:
      return {
        ...state,
        statuses: payload,
      };
    case GET_APPLICATION_STATUSES_FAIL:
      return {
        ...state,
        statuses: [],
      };
    // TAB statuses ------------[]
    case GET_APPLICATION_TAB_STATUSES:
      return {
        ...state,
        loading: true,
      };
    case GET_APPLICATION_TAB_STATUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        tabStatuses: payload,
      };
    case GET_APPLICATION_TAB_STATUSES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_PICKUP_DAYS:
      return {
        ...state,
        pickupDays: [],
      };
    case GET_PICKUP_DAYS_SUCCESS:
      return {
        ...state,
        pickupDays: payload,
      };
    case SELECT_BRANCH_ID:
      return {
        ...state,
        selected_branch_id: payload,
      };
    default:
      return state;
  }
};
