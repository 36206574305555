export const GET_ADDRESSES = 'GET_ADDRESSES';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAIL = 'GET_ADDRESSES_FAIL';

export const GET_ADDRESS = 'GET_ADDRESS';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAIL = 'GET_ADDRESS_FAIL';

export const SET_ADDRESS = 'SET_ADDRESS';

export const SET_ADDRESS_MAIN = 'SET_ADDRESS_MAIN';
export const SET_ADDRESS_MAIN_SUCCESS = 'SET_ADDRESS_MAIN_SUCCESS';
export const SET_ADDRESS_MAIN_FAIL = 'SET_ADDRESS_MAIN_FAIL';

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL';

export const PUSH_COUNTRIES = 'PUSH_COUNTRIES';
export const PUSH_COUNTRIES_SUCCESS = 'PUSH_COUNTRIES_SUCCESS';
export const PUSH_COUNTRIES_FAIL = 'PUSH_COUNTRIES_FAIL';

export const GET_REGIONS = 'GET_REGIONS';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAIL = 'GET_REGIONS_FAIL';

export const PUSH_REGIONS = 'PUSH_REGIONS';
export const PUSH_REGIONS_SUCCESS = 'PUSH_REGIONS_SUCCESS';
export const PUSH_REGIONS_FAIL = 'PUSH_REGIONS_FAIL';

export const GET_DISTRICTS = 'GET_DISTRICTS';
export const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS';
export const GET_DISTRICTS_FAIL = 'GET_DISTRICTS_FAIL';

export const PUSH_DISTRICTS = 'PUSH_DISTRICTS';
export const PUSH_DISTRICTS_SUCCESS = 'PUSH_DISTRICTS_SUCCESS';
export const PUSH_DISTRICTS_FAIL = 'PUSH_DISTRICTS_FAIL';

export const GET_SENDERS = 'GET_SENDERS';
export const GET_SENDERS_SUCCESS = 'GET_SENDERS_SUCCESS';
export const GET_SENDERS2_SUCCESS = 'GET_SENDERS2_SUCCESS';
export const GET_SENDERS_FAIL = 'GET_SENDERS_FAIL';

export const GET_RECIPIENTS = 'GET_RECIPIENTS';
export const GET_RECIPIENTS_SUCCESS = 'GET_RECIPIENTS_SUCCESS';
export const GET_RECIPIENTS_FAIL = 'GET_RECIPIENTS_FAIL';

export const ADD_RECIPIENT = 'ADD_RECIPIENT';
export const DELETE_RECIPIENT = 'DELETE_RECIPIENT';
export const SET_RECIPIENT = 'SET_RECIPIENT';

export const ADD_SENDER = 'ADD_SENDER';
export const DELETE_SENDER = 'DELETE_SENDER';
export const SET_SENDER = 'SET_SENDER';

export const GET_STREETS = 'GET_STREETS';
export const GET_STREETS_SUCCESS = 'GET_STREETS_SUCCESS';
export const GET_STREETS_FAIL = 'GET_STREETS_FAIL';

export const UPDATE_RECIPIENT = 'UPDATE_RECIPIENT';
export const UPDATE_SENDER = 'UPDATE_SENDER';
