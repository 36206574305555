import Axios from 'axios';
import { http } from '../services/api.services';

export const CommonService = {
  getLinks: () => Axios.get('https://turonexpress.com/api/menu'),

  parseUrl: data => Axios.post('https://turonexpress.com/api/product', data),
  getUnreadNotifications: () => http.get('/my-notifications/unread'),
  getNotifications: () => http.get('/my-notifications'),
  getUnreadNotificationsCount: () => http.get('/my-notifications/unread/count'),
  readNotification: id => http.get('/my-notifications/unread/' + id),
  readAllNotifications: () => http.get('/my-notifications/unread/read-all'),
};
