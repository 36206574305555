import React, { Suspense, useEffect } from 'react';
import { mobileAndTabletCheck } from './Common/utils/detector';
import { ContentPageLoader } from './Common/components/Loaders/ContentPageLoader';

const MainApp = React.lazy(() => import('./App'));
const MobileApp = React.lazy(() => import('./mobile/MobileApp'));

export const DetectRoot = () => {
  const isMobile = mobileAndTabletCheck();
  useEffect(() => {
    if (isMobile) {
      document.body.setAttribute('id', 'mobile-app');
    } else {
      document.body.setAttribute('id', 'desktop-app');
    }
  }, [isMobile]);
  return isMobile ? (
    <Suspense
      fallback={<ContentPageLoader className="full-white  h-100 w-100" />}
    >
      <MobileApp />
    </Suspense>
  ) : (
    <Suspense
      fallback={<ContentPageLoader className="full-width h-100 w-100" />}
    >
      <MainApp />
    </Suspense>
  );
};
