export const LOG_IN = "LOG_IN";
export const SEND_PHONE_REGISTER = "SEND_PHONE_REGISTER";
export const SET_USER = "SET_USER";
export const SET_IS_AUTH = "SET_IS_AUTH";
export const LOG_OUT = "LOG_OUT";

export const setUser = (payload) => ({
  type: SET_USER,
  payload
});

export const setIsAuth = (payload) => ({
  type: SET_IS_AUTH,
  payload
});

export const logout = () => ({
  type: LOG_OUT
})