export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';
export const STEP_NEXT = 'STEP_NEXT';
export const STEP_PREV = 'STEP_PREV';
export const LOADING_SUBMIT = 'LOADING_SUBMIT';
export const SET_DISABLED = 'SET_DISABLED';

export const SET_ORDER_INFO = 'SET_ORDER_INFO';
export const SET_ORDER_DECLARATION = 'SET_ORDER_DECLARATION';
export const SET_RECIPIENT_ID = 'SET_RECIPIENT_ID';
export const SET_RECEIVE_TYPE_ID = 'SET_RECEIVE_TYPE_ID';

export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAIL = 'GET_OFFERS_FAIL';

export const SET_OFFERS = 'SET_OFFERS';

export const CLEAR_ORDER = 'CLEAR_ORDER';
export const SUCCESS_ORDER = 'SUCCESS_ORDER';

export const SET_PARCEL_DECLARATION = 'SET_PARCEL_DECLARATION';

export const GET_SENDERS = 'GET_SENDERS';
export const GET_SENDERS_SUCCESS = 'GET_SENDERS_SUCCESS';
export const GET_SENDERS_FAIL = 'GET_SENDERS_FAIL';

export const SET_SENDER_ID = 'SET_SENDER_ID';

export const SET_HEADER_TEXT = 'SET_HEADER_TEXT';

export const SET_SENDER_DATA = 'SET_SENDER_DATA';
export const SET_RECEIVER_DATA = 'SET_RECEIVER_DATA';

export const SET_STEP_LENGTH = 'SET_STEP_LENGTH';

export const SET_HELP_DECLARATION = 'SET_HELP_DECLARATION';

// -------------------
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAIL = 'GET_APPLICATIONS_FAIL';

export const GET_APPLICATION_STATUSES = 'GET_APPLICATION_STATUSES';
export const GET_APPLICATION_STATUSES_SUCCESS =
  'GET_APPLICATION_STATUSES_SUCCESS';
export const GET_APPLICATION_STATUSES_FAIL = 'GET_APPLICATION_STATUSES_FAIL';

export const GET_APPLICATION_TAB_STATUSES = 'GET_APPLICATION_TAB_STATUSES';
export const GET_APPLICATION_TAB_STATUSES_SUCCESS =
  'GET_APPLICATION_TAB_STATUSES_SUCCESS';
export const GET_APPLICATION_TAB_STATUSES_FAIL =
  'GET_APPLICATION_TAB_STATUSES_FAIL';

export const APPLICATIONS_LOADING_MORE = 'APPLICATIONS_LOADING_MORE';
export const ORDERS_LOADING_MORE = 'APPLICATIONS_LOADING_MORE';

export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_FAIL = 'GET_APPLICATION_FAIL';

export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

export const GET_SHIPMENT_METHODS = 'GET_SHIPMENT_METHODS';
export const GET_SHIPMENT_METHODS_SUCCESS = 'GET_SHIPMENT_METHODS_SUCCESS';
export const GET_SHIPMENT_METHODS_FAIL = 'GET_SHIPMENT_METHODS_FAIL';

export const REMOVE_APPLICATION = 'REMOVE_APPLICATION';

export const GET_PICKUP_DAYS = 'GET_PICKUP_DAYS';
export const GET_PICKUP_DAYS_SUCCESS = 'GET_PICKUP_DAYS_SUCCESS';
export const GET_PICKUP_DAYS_FAIL = 'GET_PICKUP_DAYS_FAIL';

export const SET_TARIFF = 'SET_TARIFF';

export const SELECT_BRANCH_ID = 'SELECT_BRANCH_ID';
