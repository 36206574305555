import { takeLatest, put, delay, call } from 'redux-saga/effects';
import {
  SET_SUCCESS_MESSAGE,
  SET_ERROR_MESSAGE,
  CLEAR_NOTIFICATION,
  GET_LINKS,
  GET_LINKS_SUCCESS,
  GET_LINKS_FAIL,
} from './common.actions';
import { CommonService } from './common.services';

function* workerClear() {
  yield delay(500);
  yield put({
    type: CLEAR_NOTIFICATION,
  });
}

function* workerGetLinks() {
  try {
    const response = yield call(CommonService.getLinks);

    yield put({
      type: GET_LINKS_SUCCESS,
      payload: response.data.message,
    });
  } catch (error) {
    yield put({
      type: GET_LINKS_FAIL,
    });
  }
}

export function* commonWatcher() {
  yield takeLatest(SET_SUCCESS_MESSAGE, workerClear);
  yield takeLatest(SET_ERROR_MESSAGE, workerClear);

  yield takeLatest(GET_LINKS, workerGetLinks);
}
