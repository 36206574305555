import { GET_SHOPS, GET_SHOPS_SUCCESS, GET_SHOPS_FAIL } from "./shops.actions";

const initialState = {
  loading: false,
  shops: [],
}

export const ShopReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch(type) {
    case GET_SHOPS:
      return {
        ...state,
        loading: true
      }
    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        loading: false,
        shops: payload
      }
    case GET_SHOPS_FAIL:
      return {
        ...state,
        loading: false
      }
    default: 
      return state
  }
}