import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { getCountriesPrefix } from './Common/redux/phone/phone.action';

import rootReducer from './Common/redux/rootReducer';
import rootSaga from './Common/redux/rootSaga';

// create Saga middleware
const sagaMiddleware = createSagaMiddleware();

// add to middleware variable
const middleware = [sagaMiddleware];

// for Redux dev tools
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));

// create our store
const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);

store.dispatch(getCountriesPrefix());

export default store;
