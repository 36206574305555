import { takeLatest, put } from 'redux-saga/effects';
import { setToken } from '../../../Common/utils/helpers';
import { LOG_OUT, setIsAuth } from '../actions/auth.actions';

function* workerLogout() {
  setToken();
  yield put(setIsAuth(false));
}

export function* authWatcher() {
  yield takeLatest(LOG_OUT, workerLogout)
}