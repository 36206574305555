export const GET_INBOX_STATUSES = 'GET_INBOX_STATUSES';
export const GET_INBOX_STATUSES_SUCCESS = 'GET_INBOX_STATUSES_SUCCESS';
export const GET_INBOX_STATUSES_FAIL = 'GET_INBOX_STATUSES_FAIL';

export const GET_HELP_TO_SHOP_STATUSES = 'GET_HELP_TO_SHOP_STATUSES';
export const GET_HELP_TO_SHOP_STATUSES_SUCCESS =
  'GET_HELP_TO_SHOP_STATUSES_SUCCESS';
export const GET_HELP_TO_SHOP_STATUSES_FAIL = 'GET_HELP_TO_SHOP_STATUSES_FAIL';

export const getInboxOutboxStatuses = () => ({
  type: GET_INBOX_STATUSES,
});

export const getHelpToShopStatuses = () => ({
  type: GET_HELP_TO_SHOP_STATUSES,
});
