import auth from './AuthToken';
import moment from 'moment';
import { createCookie } from './cookie-helper';

export const registerLocal = (data = null) => {
  if (data) {
    !!data.phone_number &&
      sessionStorage.setItem('phone_number', data.phone_number);
    !!data.phone_code && sessionStorage.setItem('phone_code', data.phone_code);
    !!data.sms_id && sessionStorage.setItem('sms_id', data.sms_id);
    !!data.code && sessionStorage.setItem('code', data.code);
  } else {
    sessionStorage.removeItem('phone_number');
    sessionStorage.removeItem('phone_code');
    sessionStorage.removeItem('sms_id');
    sessionStorage.removeItem('code');
    sessionStorage.removeItem('client_id');
  }
};

export const getRegisteredUser = () => {
  const phone_number = sessionStorage.getItem('phone_number');
  const phone_code = sessionStorage.getItem('phone_code');
  const sms_id = sessionStorage.getItem('sms_id');
  if (phone_number && sms_id && phone_code) {
    return {
      phone_code: phone_code,
      phone_number: phone_number,
      sms_id: sms_id,
    };
  } else return null;
};

export const setToken = (token = null) => {
  const hostArr = window.location.hostname.split('.');
  const domain = hostArr.slice(Math.max(hostArr.length - 2, 1)).join('.');
  if (token) {
    auth.token = token;
    localStorage.setItem('token', token);
    createCookie({
      name: 'logged_in',
      value: 1,
      days: 30,
      domain: `.${domain}`,
    });
  } else {
    auth.token = null;
    localStorage.removeItem('token');
    createCookie({
      name: 'logged_in',
      value: 0,
      days: 30,
      domain: `.${domain}`,
    });
  }
};
export const setCookieLang = (lang = null) => {
  const hostArr = window.location.hostname.split('.');
  const domain = hostArr.slice(Math.max(hostArr.length - 2, 1)).join('.');
  // if (domain === 'turonexpress.com') {
  //   const div = document.getElementById('navbar-language');
  //   if (div) {
  //     div.style.visibility = 'hidden';
  //   }
  // }
  if (lang) {
    createCookie({
      name: 'lang',
      value: lang,
      days: 30,
      domain: `.${domain}`,
    });
  }
};

export const getFullWeight = (array = [], weight = '') => {
  let _weight = 0;
  if (array && array.length) {
    for (let i = 0; i < array.length; i++) {
      _weight = _weight + array[i][weight];
    }
    return _weight;
  }
  return _weight;
};
export const getFullValue = (array = [], value = '') => {
  let _value = 0;
  if (array && array.length) {
    for (let i = 0; i < array.length; i++) {
      _value = _value + array[i][value];
    }
    return _value;
  }
  return _value;
};

export const getTotalPrice = (array = [], price = '', quantity = '') => {
  let _price = 0;
  if (array && array.length) {
    for (let i = 0; i < array.length; i++) {
      _price = _price + array[i][price] * array[i][quantity];
    }
    return _price;
  }
  return _price;
};

export const getTotalPrices = (array = [], total_price = '') => {
  let _price = 0;
  if (array && array.length) {
    for (let i = 0; i < array.length; i++) {
      _price = _price + array[i][total_price];
    }
    return _price;
  }
  return _price;
};

export const getTotalItems = (array = [], item = '', quantity = '') => {
  let total = 0;
  if (array && array.length) {
    for (let i = 0; i < array.length; i++) {
      total = total + array[i][item] * array[i][quantity];
    }
    return total;
  }
  return total;
};

export const getTotalQuantity = (array = [], quantity = '') => {
  let _quantity = 0;
  if (array && array.length) {
    for (let i = 0; i < array.length; i++) {
      _quantity = _quantity + array[i][quantity];
    }
    return _quantity;
  }
  return _quantity;
};

export const getExrtaPrice = (price = 0, limit = 0) => {
  if (price - limit > 0) {
    return price - limit;
  }
  return 0;
};

export const arrayItemStringToInt = (array = []) => {
  const _arr = array.map(a => parseInt(a));
  return _arr;
};

export const getWeekends = () => {
  let days = [];
  moment.locale('ru');
  const today = moment().isoWeekday();
  for (let i = 1; i <= 5; i++) {
    // if we haven't yet passed the day of the week that I need:
    if (today <= i) {
      // then just give me this week's instance of that day
      days.push(moment().isoWeekday(i));
    } else {
      // otherwise, give me *next week's* instance of that same day
      days.push(moment().add(1, 'weeks').isoWeekday(i));
    }
  }
  return days;
};

export function debounce(func, wait, immediate) {
  let timeout;

  return (...args) => {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}
