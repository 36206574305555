import { http } from '../../Common/services/api.services';

export const UserService = {
  getUser: () => http.get('/auth/me'),

  updateUser: data => http.put('/my-setting/update-profile', data),

  signup: data => http.post('/auth/register', data),

  login: data => http.post('/auth/login', data),

  change_password: data => http.post('/my-setting/change-password', data),

  changeLanguage: data => http.patch('/auth/update-lang', data),
};
