import { put, call, takeEvery } from 'redux-saga/effects';
import {
  GET_BRANCHES,
  GET_BRANCHES_FAIL,
  GET_BRANCHES_SUCCESS,
  GET_HOME_BRANCHES_SUCCESS,
} from './branch.actions';
import { BranchService } from './branch.services';

function* workerGetBranches({ payload }) {
  try {
    const response = yield call(BranchService.getBranches, payload);
    if (payload.is_home === 1) {
      yield put({
        type: GET_HOME_BRANCHES_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: GET_BRANCHES_SUCCESS,
        payload: response.data.data,
      });
    }
  } catch (err) {
    yield put({
      type: GET_BRANCHES_FAIL,
    });
  }
}

export function* branchWatcher() {
  yield takeEvery(GET_BRANCHES, workerGetBranches);
}
