import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  SET_BALANCE,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from './user.types';

const initialState = {
  loading: false,
  user: {},
  updating: false,
  errors: null,
  country: {},
  branch: {},
  phone: {},
};

export const UserReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload.data,
        country: payload.country,
        branch: payload.branch,
        phone: payload.phone,
      };
    case GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        user: {},
      };
    case UPDATE_USER:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        user: payload,
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        updating: false,
        errors: payload,
      };
    case SET_BALANCE:
      return {
        ...state,
        user: {
          ...state.user,
          balance: state.user.balance + payload,
        },
      };
    default:
      return state;
  }
};
