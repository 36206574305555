import { GET_BOX_TYPES, GET_BOX_TYPES_FAIL, GET_BOX_TYPES_SUCCESS } from "./boxes.actions";

const initialState = {
  loading: false,
  boxTypes: [],
}

export const BoxReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch(type) {
    case GET_BOX_TYPES:
      return {
        ...state,
        loading: true
      }
    case GET_BOX_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        boxTypes: payload
      }
    case GET_BOX_TYPES_FAIL:
      return {
        ...state,
        loading: false
      }
    default: 
      return state
  }
}