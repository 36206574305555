import { takeLatest, put, call } from 'redux-saga/effects';
import { AddressService } from '../../../UserAddress/redux/address.services';
import {
  GET_COUNTRIES_PREFIX,
  GET_COUNTRIES_PREFIX_ERROR,
  GET_COUNTRIES_PREFIX_SUCCESS,
} from './phone.action';

function* workerGetCountries() {
  try {
    const response = yield call(AddressService.getCountries, {});
    yield put({
      type: GET_COUNTRIES_PREFIX_SUCCESS,
      payload: {
        data: response.data.data,
      },
    });
  } catch (error) {
    yield put({
      type: GET_COUNTRIES_PREFIX_ERROR,
    });
  }
}

export function* phonePrefixWatcher() {
  yield takeLatest(GET_COUNTRIES_PREFIX, workerGetCountries);
}
