import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_SHOPS, GET_SHOPS_SUCCESS, GET_SHOPS_FAIL } from './shops.actions';
import { ShopService } from './shops.services';

function* workerGetShops() {
  try{
    const response = yield call(ShopService.getShops)

    yield put({
      type: GET_SHOPS_SUCCESS,
      payload: response.data.data
    });

  }catch(err){
    yield put({
      type: GET_SHOPS_FAIL
    });
  }
}

export function* shopWatcher() {
  yield takeLatest(GET_SHOPS, workerGetShops);
}