import { takeLatest, put, call, select } from 'redux-saga/effects';
import { successMessage } from '../../Common/redux/common.actions';
import { AddressService } from './address.services';
import {
  GET_ADDRESS,
  GET_ADDRESSES,
  GET_ADDRESSES_FAIL,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESS_FAIL,
  GET_ADDRESS_SUCCESS,
  GET_COUNTRIES,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_SUCCESS,
  GET_RECIPIENTS,
  // GET_SENDERS,
  GET_RECIPIENTS_FAIL,
  // GET_SENDERS_FAIL,
  GET_RECIPIENTS_SUCCESS,
  // GET_SENDERS_SUCCESS,
  GET_STREETS,
  GET_STREETS_FAIL,
  GET_STREETS_SUCCESS,
  SET_ADDRESS_MAIN,
  SET_ADDRESS_MAIN_SUCCESS,
  GET_REGIONS,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAIL,
  GET_DISTRICTS,
  GET_DISTRICTS_FAIL,
  GET_DISTRICTS_SUCCESS,
  PUSH_COUNTRIES_SUCCESS,
  PUSH_REGIONS_SUCCESS,
  PUSH_DISTRICTS_SUCCESS,
} from './address.types';

function* workerGetAddresses({ payload }) {
  try {
    const response = yield call(AddressService.getAddresses, payload);
    const addresses = response.data.data;
    const _addresses = addresses.filter(item => item.address_type !== 4);
    const _main = addresses.filter(item => item.address_type === 4);
    yield put({
      type: GET_ADDRESSES_SUCCESS,
      payload: [..._main, ..._addresses],
    });
  } catch (error) {
    yield put({
      type: GET_ADDRESSES_FAIL,
    });
  }
}

function* workerGetAddress({ payload }) {
  try {
    const response = yield call(AddressService.getAddress, payload);
    yield put({
      type: GET_ADDRESS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: GET_ADDRESS_FAIL,
    });
  }
}

function* workerSetAddressMain({ payload }) {
  const { addresses } = yield select(({ address }) => ({
    addresses: address.addresses,
  }));
  const _addresses = addresses.map(item =>
    item.id === payload.id
      ? {
          ...item,
          address_type: 4,
        }
      : {
          ...item,
          address_type: 1,
        },
  );
  const __addresses = _addresses.filter(item => item.address_type !== 4);
  const __main = _addresses.filter(item => item.address_type === 4);

  yield put({
    type: SET_ADDRESS_MAIN_SUCCESS,
    payload: [...__main, ...__addresses],
  });

  yield put(successMessage(payload.message));
}

function* workerGetCountries({ payload }) {
  const { countryMeta } = yield select(({ address }) => ({
    countryMeta: address.countryMeta,
  }));
  try {
    let response = null;
    if (payload.add && countryMeta.next) {
      response = yield call(AddressService.getCountries, {
        ...payload.params,
        page: countryMeta.current_page + 1,
      });
      yield put({
        type: PUSH_COUNTRIES_SUCCESS,
        payload: {
          data: response.data.data,
          meta: {
            ...response.data.links,
            ...response.data.meta,
          },
        },
      });
    } else if (!payload.add) {
      response = yield call(AddressService.getCountries, payload.params);
      yield put({
        type: GET_COUNTRIES_SUCCESS,
        payload: {
          data: response.data.data,
          meta: {
            ...response.data.links,
            ...response.data.meta,
          },
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_COUNTRIES_FAIL,
    });
  }
}

function* workerGetRegions({ payload }) {
  const { regionMeta } = yield select(({ address }) => ({
    regionMeta: address.regionMeta,
  }));
  try {
    let response = null;
    if (payload.add && regionMeta.next) {
      response = yield call(AddressService.getRegions, {
        ...payload.params,
        page: regionMeta.current_page + 1,
      });
      yield put({
        type: PUSH_REGIONS_SUCCESS,
        payload: {
          data: response.data.data,
          meta: {
            ...response.data.links,
            ...response.data.meta,
          },
        },
      });
    } else if (!payload.add) {
      response = yield call(AddressService.getRegions, payload.params);
      yield put({
        type: GET_REGIONS_SUCCESS,
        payload: {
          data: response.data.data,
          meta: {
            ...response.data.links,
            ...response.data.meta,
          },
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_REGIONS_FAIL,
    });
  }
}

function* workerGetDistricts({ payload }) {
  const { districtMeta } = yield select(({ address }) => ({
    districtMeta: address.districtMeta,
  }));
  try {
    let response = null;
    if (payload.add && districtMeta.next) {
      response = yield call(AddressService.getDistricts, {
        ...payload.params,
        page: districtMeta.current_page + 1,
      });
      yield put({
        type: PUSH_DISTRICTS_SUCCESS,
        payload: {
          data: response.data.data,
          meta: {
            ...response.data.links,
            ...response.data.meta,
          },
        },
      });
    } else if (!payload.add) {
      response = yield call(AddressService.getDistricts, payload.params);
      yield put({
        type: GET_DISTRICTS_SUCCESS,
        payload: {
          data: response.data.data,
          meta: {
            ...response.data.links,
            ...response.data.meta,
          },
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_DISTRICTS_FAIL,
    });
  }
}

function* workerGetStreets({ payload }) {
  try {
    const response = yield call(AddressService.searchStreet, payload);

    yield put({
      type: GET_STREETS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: GET_STREETS_FAIL,
    });
  }
}

function* workerGetRecipients({ payload }) {
  try {
    const response = yield call(AddressService.getRecipients, payload);

    yield put({
      type: GET_RECIPIENTS_SUCCESS,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: GET_RECIPIENTS_FAIL,
    });
  }
}

// function* workerGetSenders({ payload }) {
//   try {
//     const response = yield call(AddressService.getSenders, payload);

//     yield put({
//       type: GET_SENDERS_SUCCESS,
//       payload: response?.data?.message,
//     });
//   } catch (err) {
//     yield put({
//       type: GET_SENDERS_FAIL,
//     });
//   }
// }

export function* addressWatcher() {
  yield takeLatest(GET_ADDRESSES, workerGetAddresses);
  yield takeLatest(GET_ADDRESS, workerGetAddress);
  yield takeLatest(SET_ADDRESS_MAIN, workerSetAddressMain);
  yield takeLatest(GET_COUNTRIES, workerGetCountries);
  yield takeLatest(GET_REGIONS, workerGetRegions);
  yield takeLatest(GET_DISTRICTS, workerGetDistricts);
  yield takeLatest(GET_STREETS, workerGetStreets);
  yield takeLatest(GET_RECIPIENTS, workerGetRecipients);
  // yield takeLatest(GET_SENDERS, workerGetSenders);
}
