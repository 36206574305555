import auth from '../../../Common/utils/AuthToken';
import { GET_USER_FAIL } from '../../../UserSettings/redux/user.types';
import { SET_IS_AUTH, SET_USER } from '../actions/auth.actions';

const initialState = {
	loading: false,
	isAuth: !!auth.token,
	user: {}
}

export const AuthReducer = (state = initialState, action) => {

	const { type, payload } = action;

	switch (type) {
		case SET_USER:
			return {
				...state,
				user: payload,
				isAuth: true
			}
		case SET_IS_AUTH:
			return {
				...state,
				isAuth: payload
			}
		case GET_USER_FAIL:
			return {
				...state,
				isAuth: false
			}
		default:
			return state
	}
}