export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_INVOICES = 'FETCH_INVOICES';

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_INVOICES = 'GET_INVOICES';

export const CANCEL_INVOICE = 'CANCEL_INVOICE';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';

export const GET_ALL_CASHBACKS = 'GET_ALL_CASHBACKS';
export const GET_ALL_CASHBACKS_SUCCESS = 'GET_ALL_CASHBACKS_SUCCESS';
export const GET_ALL_CASHBACKS_FAIL = 'GET_ALL_CASHBACKS_FAIL';
export const PUSH_CASHBACKS = 'PUSH_CASHBACKS';
