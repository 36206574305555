import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  errorMessage,
  successMessage,
} from '../../Common/redux/common.actions';
import { BalanceService } from './balance.services';
import {
  CANCEL_INVOICE,
  FETCH_INVOICES,
  FETCH_TRANSACTIONS,
  GET_ALL_CASHBACKS,
  GET_ALL_CASHBACKS_FAIL,
  GET_ALL_CASHBACKS_SUCCESS,
  GET_INVOICES,
  GET_TRANSACTIONS,
  UPDATE_INVOICE,
  PUSH_CASHBACKS,
} from './balance.types';

function* workerFetchTransactions({ payload }) {
  const { transactionMeta } = yield select(({ balance }) => ({
    transactionMeta: balance.transactionMeta,
  }));
  try {
    let response = null;
    if (!payload.resetData) {
      response = yield call(BalanceService.fetchMyTransactions, {
        page: transactionMeta.current_page + 1,
      });
      yield put({
        type: FETCH_TRANSACTIONS,
        payload: {
          data: response.data.data,
          resetData: payload.resetData,
          transactionMeta: {
            ...response.data.meta,
            ...response.data.links,
          },
        },
      });
    } else {
      response = yield call(BalanceService.fetchMyTransactions, {});
      yield put({
        type: FETCH_TRANSACTIONS,
        payload: {
          data: response.data.data,
          resetData: payload.resetData,
          transactionMeta: {
            ...response.data.meta,
            ...response.data.links,
          },
        },
      });
    }
  } catch (err) {
    //
  }
}

function* workerFetchInvoices({ payload }) {
  const res = yield call(BalanceService.fetchMyInvoices, payload.params);
  yield put({
    type: FETCH_INVOICES,
    payload: { data: res.data.data, resetData: payload.resetData },
  });
}

function* workerGetAllCashBacks({ add }) {
  const { cashbackMeta } = yield select(({ balance }) => ({
    cashbackMeta: balance.cashbackMeta,
  }));
  try {
    let response = null;
    if (add && cashbackMeta.next) {
      const params = {
        page: cashbackMeta.current_page + 1,
      };
      response = yield call(BalanceService.getAllCashbacks, params);

      yield put({
        type: PUSH_CASHBACKS,
        payload: {
          data: response.data.data,
          meta: {
            ...response.data.links,
            ...response.data.meta,
          },
        },
      });
    } else {
      response = yield call(BalanceService.getAllCashbacks, { page: 1 });
      yield put({
        type: GET_ALL_CASHBACKS_SUCCESS,
        payload: {
          data: response.data.data,
          meta: {
            ...response.data.links,
            ...response.data.meta,
          },
        },
      });
    }
  } catch (err) {
    yield put({
      type: GET_ALL_CASHBACKS_FAIL,
    });
  }
}

function* workerCancelInvoice({ payload }) {
  try {
    const res = yield call(BalanceService.cancelInvoice, payload.token);
    yield put({
      type: UPDATE_INVOICE,
      payload: res.data.data,
    });
    yield put(successMessage(res.data.message));
  } catch (err) {
    if (err?.response) {
      put(errorMessage(err.response.data.error || err.response.data.message));
    }
  }
}

export function* balanceWatcher() {
  yield takeLatest(GET_TRANSACTIONS, workerFetchTransactions);
  yield takeLatest(GET_INVOICES, workerFetchInvoices);
  yield takeLatest(CANCEL_INVOICE, workerCancelInvoice);
  yield takeLatest(GET_ALL_CASHBACKS, workerGetAllCashBacks);
}
