import {
  GET_HELP_TO_SHOP_STATUSES_SUCCESS,
  GET_INBOX_STATUSES_SUCCESS,
} from './statuses.action';

const initialState = {
  inboxStatuses: [],
  helpToShopStatuses: [],
};

export const StatusesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_INBOX_STATUSES_SUCCESS:
      return {
        ...state,
        inboxStatuses: payload,
      };
    case GET_HELP_TO_SHOP_STATUSES_SUCCESS:
      return {
        ...state,
        helpToShopStatuses: payload,
      };
    default:
      return state;
  }
};
