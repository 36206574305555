// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import './Common/i18n';
import { DetectRoot } from './DetectRoot';
import { http } from './Common/services/api.services';
import { logout } from './Auth/redux/actions/auth.actions';
import { toggleModal } from './Common/redux/common.actions';
import { NETWORK_ERROR } from './Common/Modals/modal.contants';
import { createCookie } from './Common/utils/cookie-helper';

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');

      const hostArr = window.location.hostname.split('.');
      const domain = hostArr.slice(Math.max(hostArr.length - 2, 1)).join('.');
      createCookie({
        name: 'logged_in',
        value: 0,
        days: 30,
        domain: `.${domain}`,
      });
      store.dispatch(logout());
    } else if (error.message === 'Network Error') {
      store.dispatch(toggleModal(NETWORK_ERROR));
    }
    // else if (error.response?.status === 500) {
    //   store.dispatch(toggleModal(ERROR_MODAL));
    // }
    throw error;
  },
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <DetectRoot />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
