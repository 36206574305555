import { takeLatest, put, call } from 'redux-saga/effects';
import { BoxService } from './boxes.services';
import { GET_BOX_TYPES, GET_BOX_TYPES_FAIL, GET_BOX_TYPES_SUCCESS } from './boxes.actions';

function* workerGetBoxes() {
  try{
    const response = yield call(BoxService.getBoxTypes)

    yield put({
      type: GET_BOX_TYPES_SUCCESS,
      payload: response.data.data
    })

  }catch(err){
    yield put({
      type: GET_BOX_TYPES_FAIL
    })
  }
}

export function* boxWatcher() {
  yield takeLatest(GET_BOX_TYPES, workerGetBoxes);
}