import { takeLatest, put, call } from 'redux-saga/effects';
import { OrderService } from './order.services';
import {
  GET_OFFERS,
  GET_OFFERS_FAIL,
  GET_OFFERS_SUCCESS,
  GET_SENDERS,
  GET_SENDERS_FAIL,
  GET_SENDERS_SUCCESS,
  GET_APPLICATIONS,
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_FAIL,
  GET_APPLICATION_STATUSES,
  GET_APPLICATION_STATUSES_SUCCESS,
  GET_APPLICATION_STATUSES_FAIL,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAIL,
  GET_APPLICATION,
  GET_SHIPMENT_METHODS,
  GET_SHIPMENT_METHODS_FAIL,
  GET_SHIPMENT_METHODS_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  GET_APPLICATION_TAB_STATUSES,
  GET_APPLICATION_TAB_STATUSES_SUCCESS,
  GET_APPLICATION_TAB_STATUSES_FAIL,
  GET_PICKUP_DAYS,
  GET_PICKUP_DAYS_SUCCESS,
  GET_PICKUP_DAYS_FAIL,
} from './order.types';

function* workerGetOffers({ payload }) {
  try {
    const response = yield call(OrderService.getOffers, payload);

    yield put({
      type: GET_OFFERS_SUCCESS,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: GET_OFFERS_FAIL,
    });
  }
}
function* workerGetSenders({ payload }) {
  try {
    const response = yield call(OrderService.getSenders, payload);

    yield put({
      type: GET_SENDERS_SUCCESS,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: GET_SENDERS_FAIL,
    });
  }
}

function* workerGetApplications({ payload }) {
  try {
    const response = yield call(OrderService.getApplications, payload);
    const _data = response.data.data.map((item, index) => ({
      ...item,
      index: index,
    }));
    yield put({
      type: GET_APPLICATIONS_SUCCESS,
      payload: _data,
    });
  } catch (err) {
    yield put({
      type: GET_APPLICATIONS_FAIL,
    });
  }
}

function* workerGetApplicationStatuses({ payload }) {
  try {
    const response = yield call(OrderService.getApplicationsStatuses, payload);

    yield put({
      type: GET_APPLICATION_STATUSES_SUCCESS,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: GET_APPLICATION_STATUSES_FAIL,
    });
  }
}

function* workerGetApplicationTabStatuses({ payload }) {
  try {
    const response = yield call(
      OrderService.getApplicationTabStatuses,
      payload,
    );

    yield put({
      type: GET_APPLICATION_TAB_STATUSES_SUCCESS,
      payload: {
        statuses: response.data.data,
        tab: payload,
      },
    });
  } catch (err) {
    yield put({
      type: GET_APPLICATION_TAB_STATUSES_FAIL,
    });
  }
}

function* workerGetApplication({ payload }) {
  try {
    const response = yield call(OrderService.getApplication, payload);
    yield put({
      type: GET_APPLICATION_SUCCESS,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: GET_APPLICATION_FAIL,
    });
  }
}

function* workerGetPickupDays({ payload }) {
  try {
    const response = yield call(OrderService.getPickupDays, payload);
    yield put({
      type: GET_PICKUP_DAYS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: GET_PICKUP_DAYS_FAIL,
    });
  }
}

function* workerGetOrder({ payload }) {
  try {
    const response = yield call(OrderService.getOrder, payload);
    yield put({
      type: GET_ORDER_SUCCESS,
      payload: response.data.data,
    });
  } catch (err) {
    yield put({
      type: GET_ORDER_FAIL,
    });
  }
}

function* workerGetShipmentMethods({ payload }) {
  try {
    const response = yield call(OrderService.getShipmentMethods, payload);
    yield put({
      type: GET_SHIPMENT_METHODS_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: GET_SHIPMENT_METHODS_FAIL,
    });
  }
}

export function* orderWatcher() {
  yield takeLatest(GET_OFFERS, workerGetOffers);
  yield takeLatest(GET_SENDERS, workerGetSenders);

  yield takeLatest(GET_APPLICATIONS, workerGetApplications);
  yield takeLatest(GET_APPLICATION, workerGetApplication);
  yield takeLatest(GET_PICKUP_DAYS, workerGetPickupDays);
  yield takeLatest(GET_ORDER, workerGetOrder);
  yield takeLatest(GET_APPLICATION_STATUSES, workerGetApplicationStatuses);
  yield takeLatest(
    GET_APPLICATION_TAB_STATUSES,
    workerGetApplicationTabStatuses,
  );

  yield takeLatest(GET_SHIPMENT_METHODS, workerGetShipmentMethods);
}
