import {
  ADD_ADDRESS,
  ADD_RECIPIENT,
  ADD_SENDER,
  DELETE_ADDRESS,
  DELETE_RECIPIENT,
  DELETE_SENDER,
  GET_ADDRESS,
  GET_ADDRESSES,
  GET_ADDRESSES_FAIL,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESS_FAIL,
  GET_ADDRESS_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_DISTRICTS_SUCCESS,
  GET_RECIPIENTS,
  GET_SENDERS,
  GET_RECIPIENTS_FAIL,
  GET_SENDERS_FAIL,
  GET_RECIPIENTS_SUCCESS,
  GET_SENDERS_SUCCESS,
  GET_SENDERS2_SUCCESS,
  GET_REGIONS_SUCCESS,
  GET_STREETS_SUCCESS,
  PUSH_COUNTRIES_SUCCESS,
  PUSH_DISTRICTS_SUCCESS,
  PUSH_REGIONS_SUCCESS,
  SET_ADDRESS,
  SET_ADDRESS_MAIN,
  SET_ADDRESS_MAIN_FAIL,
  SET_ADDRESS_MAIN_SUCCESS,
  SET_RECIPIENT,
  SET_SENDER,
  UPDATE_RECIPIENT,
  UPDATE_SENDER,
} from './address.types';

const initialState = {
  loading: false,
  addresses: [],
  updating: false,
  errors: null,
  address: {},
  countries: [],
  regions: [],
  districts: [],
  countryMeta: {},
  regionmeta: {},
  districtMeta: {},
  recipients: [],
  senders: [],
  recipient: {},
  sender: {},
  streets: [],
};

export const AddressReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ADDRESSES:
      return {
        ...state,
        loading: true,
      };
    case GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        addresses: payload,
      };
    case GET_ADDRESSES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_ADDRESS:
      return {
        ...state,
        loading: true,
        address: {},
      };
    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        address: payload,
      };
    case GET_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        address: null,
      };
    case SET_ADDRESS:
      return {
        ...state,
        address: payload,
      };
    case ADD_ADDRESS:
      return {
        ...state,
        addresses: [...state.addresses, { ...payload }],
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        addresses: state.addresses.filter(item => item.id !== payload),
      };
    case SET_ADDRESS_MAIN:
      return {
        ...state,
        updating: true,
      };
    case SET_ADDRESS_MAIN_SUCCESS:
      return {
        ...state,
        updating: false,
        addresses: payload,
      };
    case SET_ADDRESS_MAIN_FAIL:
      return {
        ...state,
        updating: false,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload.data,
        countryMeta: payload.meta,
      };
    case PUSH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: [...state.countries, ...payload.data],
        countryMeta: payload.meta,
      };
    case PUSH_REGIONS_SUCCESS:
      return {
        ...state,
        regions: [...state.regions, ...payload.data],
        regionMeta: payload.meta,
      };
    case PUSH_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: [...state.districts, ...payload.data],
        districtMeta: payload.meta,
      };
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: payload.data,
        regionMeta: payload.meta,
      };
    case GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: payload.data,
        districtMeta: payload.meta,
      };
    case GET_RECIPIENTS:
      return {
        ...state,
        loading: true,
        recipients: [],
      };
    case GET_SENDERS:
      return {
        ...state,
        loading: true,
      };
    case GET_RECIPIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        recipients: payload,
      };
    case GET_SENDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        senders: payload,
      };
    case GET_SENDERS2_SUCCESS:
      return {
        ...state,
        loading: false,
        senders: payload,
      };
    case GET_RECIPIENTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_SENDERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ADD_RECIPIENT:
      return {
        ...state,
        recipients: [...state.recipients, { ...payload }],
      };
    case ADD_SENDER:
      return {
        ...state,
        sender: [...state.sender, { ...payload }],
      };
    case DELETE_RECIPIENT:
      return {
        ...state,
        recipients: state.recipients.filter(item => item.id !== payload),
      };
    case DELETE_SENDER:
      return {
        ...state,
        sender: state.sender.filter(item => item.id !== payload),
      };
    case SET_RECIPIENT:
      return {
        ...state,
        recipient: payload,
      };
    case SET_SENDER:
      return {
        ...state,
        sender: payload,
      };
    case UPDATE_RECIPIENT:
      return {
        ...state,
        recipient: payload,
        recipients: state.recipients.map(item =>
          item.id === payload.id ? { ...payload } : item,
        ),
      };
    case UPDATE_SENDER:
      return {
        ...state,
        sender: payload,
        senders: state.senders.map(item =>
          item.id === payload.id ? { ...payload } : item,
        ),
      };
    case GET_STREETS_SUCCESS:
      return {
        ...state,
        streets: payload,
      };
    default:
      return state;
  }
};
