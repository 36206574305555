import { takeLatest, put, call } from 'redux-saga/effects';
import { OrderService } from '../../../UserOrder/redux/order.services';
import {
  GET_HELP_TO_SHOP_STATUSES,
  GET_HELP_TO_SHOP_STATUSES_FAIL,
  GET_HELP_TO_SHOP_STATUSES_SUCCESS,
  GET_INBOX_STATUSES,
  GET_INBOX_STATUSES_FAIL,
  GET_INBOX_STATUSES_SUCCESS,
} from './statuses.action';

function* workerGetInboxStatuses() {
  try {
    const response = yield call(OrderService.getApplicationTabStatuses, 0);

    yield put({
      type: GET_INBOX_STATUSES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: GET_INBOX_STATUSES_FAIL,
    });
  }
}

function* workerGetHelpStatuses() {
  try {
    const response = yield call(OrderService.getApplicationTabStatuses, 1);

    yield put({
      type: GET_HELP_TO_SHOP_STATUSES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: GET_HELP_TO_SHOP_STATUSES_FAIL,
    });
  }
}

export function* statusesWatcher() {
  yield takeLatest(GET_INBOX_STATUSES, workerGetInboxStatuses);
  yield takeLatest(GET_HELP_TO_SHOP_STATUSES, workerGetHelpStatuses);
}
